export enum TaskStatus {
  Draft = 'Черновик',
  Appointed = 'Новая',
  InProgs = 'В работе',
  Await = 'Ожидание',
  Done = 'Выполнена',
  Confirmation = 'Завершена',
  Canceled = 'Отменена',
}

export enum NewTaskStatus {
  Draft = 'Черновик',
  Appointed = 'Новая',
}

export enum PriorityType {
  veryLow = 'Очень низкий',
  low = 'Низкий',
  medium = 'Средний',
  high = 'Важный',
  veryHigh = 'Очень важный',
}

export enum ModalType {
  edit = 'edit',
  err = 'err',
  newApp = 'new',
  user = 'user',
  confirm = 'confirm',
}
