import React from 'react';
import styles from './NoMobilePage.module.scss';
import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import NoPage from '@components/NoPage/NoPage';

const NoMobilePage = () => {
  return (
    <main className={styles.page}>
      <Header pageName={'Страница не найдена'} />
      <NoPage />
      <Footer />
    </main>
  );
};

export default ProtectedRoute(NoMobilePage);
