import React, { useState } from 'react';

import styles from './MoreMobilePage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import MoreSettings from '@components/MoreSettings/MoreSettings';
import ModalExit from '@components/Modal/ModalExit/ModalExit';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const MoreMobilePage = () => {
  const [isSettingsOpen, setSettingsOpen] = useState(true);
  const [openResultModal, setResultModal] = useState(false);

  return (
    <main className={styles.page}>
      <Header
        pageName={'Дополнительно'}
        isModal={!isSettingsOpen}
        handleOpenModal={() => setSettingsOpen(true)}
      />
      <MoreSettings
        setResultModal={setResultModal}
        isSettingsOpen={isSettingsOpen}
        setSettingsOpen={setSettingsOpen}
      />
      <Footer />
      <ModalExit isOpen={openResultModal} closeModal={() => setResultModal(false)} />
    </main>
  );
};

export default ProtectedRoute(MoreMobilePage);
