import React from 'react';
import styles from './NoPage.module.scss';

const NoPage = () => {
  return (
    <div className={styles.info}>
      <h3 className={styles.heading}>Ошибка 404</h3>
      <p className={styles.subheading}>Страница не найдена либо находится в разработке</p>
    </div>
  );
};

export default NoPage;
