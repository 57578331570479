import React from 'react';

import styles from './NotificationDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { Applications } from '@components/Applications/Applications/Applications';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import NotificationBar from '@components/NotificationBar/NotificationBar';

const NotificationDeskPage = () => {
  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Уведомления'} />

        <div className={styles.body}>
          <Applications />
          <div className={styles.reports}>
            <div className={styles.comments}></div>
            <div className={styles.appInfo}>
              <NotificationBar />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(NotificationDeskPage);
