import React from 'react';
import cn from 'classnames';
import styles from './ModalUpdate.module.scss';

type IProps = {
  isOpen: boolean;
  closeModal: () => void;
  handleConfirm: () => void;
};

const ModalUpdate: React.FC<IProps> = ({ isOpen, closeModal, handleConfirm }) => {
  return (
    <div className={cn(styles.modal, isOpen && styles.modal_open)}>
      <button className={styles.submitButton} onClick={handleConfirm}>
        Доступно обновление. Загрузить?
      </button>
      <button className={styles.closeButton} onClick={() => closeModal()}></button>
    </div>
  );
};

export default React.memo(ModalUpdate);
