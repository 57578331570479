import React, { useEffect, useState } from 'react';

import styles from '../MoreSettings.module.scss';
import { BeforeInstallPromptEvent } from '@shared/types';

const PwaComponent = () => {
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);
    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onClick = () => {
    if (!promptInstall) return;
    promptInstall.prompt();
  };

  return (
    <>
      {!!promptInstall && (
        <div className={styles.link} onClick={() => onClick()}>
          <span className={styles.rixap} />
          <p className={styles.text}>Установить приложение</p>
        </div>
      )}
    </>
  );
};

export default PwaComponent;
