import React, { useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ApplicationsMobilePage.module.scss';
import { AppDispatch, RootState } from '@redux/store';
import { setFilterModalState } from '@redux/slices/filterSlices';

import Header from '@components/Header/Header';
import { Footer } from '@components/Footer/Footer';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { Applications } from '@components/Applications/Applications/Applications';
import { AuthContext } from '@contexts/CurrUserContext';

const ApplicationsMobilePage = () => {
  const { isOpen } = useSelector((state: RootState) => state.filter);
  const { userInfo } = useContext(AuthContext);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <main className={cn(styles.page, isOpen && styles.page_blocked)}>
      <Header
        pageName={isOpen ? 'Фильтры' : 'Заявки'}
        isModal={isOpen}
        handleOpenModal={() => dispatch(setFilterModalState(false))}
      />
      <Applications />
      {(userInfo.IsCoordinator || userInfo.IsSeniorCoordinator || userInfo.IsSeniorExecutor) && (
        <Link to={'/applications/new-application'} className={styles.newTask} />
      )}
      <Footer />
    </main>
  );
};

export default ProtectedRoute(ApplicationsMobilePage);
