import React, { useState } from 'react';

import styles from './UsersDescPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import { ApplicationsModal } from '@components/Applications/Applications/Applications';
import Users from '@components/Users/Users/Users';

const UsersDescPage = () => {
  const [isAppListOpen, setAppListOpen] = useState(false);

  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Инициаторы'} />
        <div className={styles.body}>
          <ApplicationsModal isAppListOpen={isAppListOpen} setAppListOpen={setAppListOpen} />

          <div className={styles.additional}>
            <div className={styles.comments}>
              <Users />
            </div>
            <div className={styles.appInfo}></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(UsersDescPage);
