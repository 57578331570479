import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { InitiatorsType, ServiceType, UserType } from '@shared/types';
import { fetchPOSTData } from 'api';

interface appsState {
  // isAppsLoading: boolean;
  isAppsRefreshing: boolean;
  isInitiatorsArrRefreshing: boolean;
  servicesArr: ServiceType[];
  coordinatorsArr: UserType[];
  executorsArr: UserType[];
  initiatorsArr: InitiatorsType[];
  errorMessage: string;
}

const initialState: appsState = {
  // isAppsLoading: true,
  isAppsRefreshing: false,
  isInitiatorsArrRefreshing: false,
  servicesArr: [],
  coordinatorsArr: [],
  executorsArr: [],
  initiatorsArr: [],
  errorMessage: '',
};

const AppsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setServices(state, action: PayloadAction<ServiceType[]>) {
      return { ...state, servicesArr: action.payload };
    },
    setUsers(state, action: PayloadAction<UserType[]>) {
      return {
        ...state,
        coordinatorsArr: action.payload.filter((item) => item.IsCoordinator),
        executorsArr: action.payload.filter((item) => item.IsExecutor),
      };
    },
    setInitiators(state, action: PayloadAction<InitiatorsType[]>) {
      return { ...state, initiatorsArr: action.payload };
    },
    setInitiatorsArrRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isInitiatorsArrRefreshing: action.payload };
    },
    setAppsRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isAppsRefreshing: action.payload };
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload };
    },
  },
});

export const {
  setServices,
  setUsers,
  setInitiators,
  setErrorMessage,
  setInitiatorsArrRefreshing,
  setAppsRefreshing,
} = AppsSlice.actions;

export default AppsSlice.reducer;

export const fetchServices = createAsyncThunk('apps/fetchServices', async (_, { dispatch }) => {
  await fetchPOSTData('Services', dispatch, setServices, setErrorMessage);
});

// export const fetchUsers = createAsyncThunk('apps/fetchUsers', async (_, { dispatch }) => {
//   await fetchPOSTData('users', dispatch, setUsers, setErrorMessage);
// });

export const fetchInitiators = createAsyncThunk('apps/fetchInitiators', async (_, { dispatch }) => {
  await fetchPOSTData('Initiators', dispatch, setInitiators, setErrorMessage);
});
