import styled from 'styled-components';
import { useNavigate } from 'react-router';
import defaultAvatar from '@assets/default_avatar.svg';
import newUser from './assets/logo_newSubtask.svg';

const StyledChatItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 15px 20px;
  background-color: ${(props) => (props.theme.selected ? '#E8EAFD' : 'transparent')};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    background-color: #e8eafd;
  }
`;

const StyledUserImage = styled.img`
  width: 34px;
  height: 34px;
`;

const StyledChatHeading = styled.h4`
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
`;

const StyledChatSubheading = styled.p`
  color: #7f7f7f;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

interface ITaskProps {
  imgLink: string;
  userName: string;
  jobTitle: string;
  department: string;
  selected: boolean;
  onClick: () => void;
}

export const ChatUser = ({
  imgLink,
  userName,
  jobTitle,
  department,
  selected,
  onClick,
}: ITaskProps) => {
  return (
    <StyledChatItem theme={{ selected }} onClick={onClick}>
      <StyledUserImage src={imgLink.length ? imgLink : defaultAvatar} alt={userName} />
      <div>
        <StyledChatHeading>{userName}</StyledChatHeading>
        <StyledChatSubheading>{jobTitle}</StyledChatSubheading>
        <StyledChatSubheading>{department}</StyledChatSubheading>
      </div>
    </StyledChatItem>
  );
};

export const NewChatUser = () => {
  const navigate = useNavigate();

  return (
    <StyledChatItem onClick={() => navigate('/users/new-user')}>
      <StyledUserImage src={newUser} alt={'new-user'} />
      <StyledChatHeading>Добавить инициатора</StyledChatHeading>
    </StyledChatItem>
  );
};
