import React, { useCallback, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { AppDispatch, RootState } from '@redux/store';
import styles from './Applications.module.scss';
import { AppType } from '@shared/types';
import { TaskStatus } from '@shared/enums';
import { resetSomeFiltersFields, setFilterModalState } from '@redux/slices/filterSlices';

// import { Card } from '@components/UI/Card';
import NewCard from '@components/UI/NewCard/NewCard';
import Preloader from '@components/UI/Preloader/Preloader';
import { ModalAppFilter } from '@components/Modal/ModalAppFilter/ModalAppFilter';
import FilterInput from '@components/UI/FilterInput';
import ExtendButton from '@components/UI/ExtendButton';
import { fetchAPISimple } from 'api';
import { useDebounce } from '@shared/hooks/useDebounce';
import { VariableSizeList, FixedSizeList as List, FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';

// const NewCard = React.lazy(() => import('@components/UI/NewCard/NewCard'));
// const Card = React.lazy(() => import('@components/UI/Card'));

export const Applications = () => {
  const { application } = useParams();
  const {
    isOpen,
    isActive,
    statusFilter,
    isWithoutExecutor,
    servFilter,
    initiatorFilter,
    coordinatorFilter,
    categoryFilter,
    userFilter,
  } = useSelector((state: RootState) => state.filter);
  const { isAppsRefreshing } = useSelector((state: RootState) => state.apps);
  const [appDesc, setAppDesc] = useState<string>('');
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const debouncedValue = useDebounce<string>(appDesc, 500);

  // сборщик тела запроса из фильтров
  const appBodyReq = useCallback(() => {
    const reqBody = {};
    servFilter.UID && Object.assign(reqBody, { ApplicationServise: servFilter.UID });
    userFilter.UID && Object.assign(reqBody, { ApplicationTo: userFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { ApplicationFrom: initiatorFilter.UID });
    coordinatorFilter.UID &&
      Object.assign(reqBody, { ApplicationCoordinator: coordinatorFilter.UID });
    categoryFilter.UID && Object.assign(reqBody, { category: categoryFilter.UID });
    statusFilter.length && Object.assign(reqBody, { statusFilter: statusFilter });
    isWithoutExecutor && Object.assign(reqBody, { ApplicationTo: '' });
    !!debouncedValue && Object.assign(reqBody, { search: debouncedValue });
    return reqBody;
  }, [
    categoryFilter.UID,
    coordinatorFilter.UID,
    initiatorFilter.UID,
    servFilter.UID,
    userFilter.UID,
    statusFilter,
    debouncedValue,
    isWithoutExecutor,
  ]);

  const containerRef = useRef(null);

  // useEffect(() => {
  //   fetchMoreTasks();
  // }, []);

  // const handleScroll = () => {
  //   const container = containerRef.current;
  //   if (container) {
  //     const { scrollTop, clientHeight, scrollHeight } = container;
  //     if (scrollHeight - scrollTop === clientHeight) {
  //       fetchMoreTasks();
  //     }
  //   }
  // };

  useEffect(() => {
    setIsLoading(true);
    setCurrTasks([]);
    setCurrPage(1);
    dispatch(setFilterModalState(false));
    fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
      .then((res) => setCurrTasks(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
    const updateInterval = localStorage.getItem('updateInterval');
    if (!!updateInterval && Number(updateInterval)) {
      const interval = setInterval(() => {
        fetchAPISimple('Applications', { part: 1, ...appBodyReq() })
          .then((res) => setCurrTasks([...currTasks, ...res]))
          .catch((err) => console.error(err));
      }, +updateInterval * 6000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBodyReq, isAppsRefreshing]);

  const handleReverseArr = () =>
    setCurrTasks(
      currTasks
        .slice(0)
        .reverse()
        .map((element) => {
          return element;
        })
    );

  const handleOnClickByFilters = (v: 'free' | 'Appointed' | 'InProgs') => {
    setIsLoading(true);
    switch (v) {
      case 'free':
        dispatch(resetSomeFiltersFields({ isWithoutExecutor: !isWithoutExecutor }));
        return;
      case 'Appointed':
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['Appointed']] }));
        return;
      default:
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['InProgs']] }));
        return;
    }
  };

const fetchMoreTasks = () => {
  if (isLoading) return; // Если данные уже загружаются, выйти из функции
  setIsLoading(true); // Установить isLoading в true перед отправкой запроса

  const nextPage = currPage + 1; // Сохранить значение следующей страницы

  fetchAPISimple('Applications', { part: nextPage, ...appBodyReq() }) // Используйте значение nextPage для запроса
    .then((res) => {
      setIsLoading(false); // После получения ответа с сервера установить isLoading в false
      if (res.length === 0) {
        setIsEnd(true);
      } else {
        setCurrPage(nextPage); // Установить следующую страницу
        setCurrTasks((prevTasks) => [...prevTasks, ...res]);
      }
    })
    .catch((err) => {
      console.error(err);
      setIsLoading(false); // Обработка ошибки: установить isLoading в false
    });
};

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
      <FilterInput onChange={setAppDesc} />
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
          <span className={cn(styles.filters, isActive && styles.filters_active)} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>

      <div className={styles.tagContainer}>
        {/* <ScrollContainer className={styles.tags} vertical={false}> */}
        <div className={styles.tags}>
          <button
            className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
            onClick={() => handleOnClickByFilters('free')}
          >
            Свободные
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('InProgs')}
          >
            В работе
          </button>
        </div>
        {/* </ScrollContainer> */}
      </div>

      {/* <InfiniteLoader
        isItemLoaded={(index) => index < currTasks.length}
        itemCount={isEnd ? currTasks.length : currTasks.length + 1}
        loadMoreItems={fetchMoreTasks}
      > */}
      <InfiniteLoader
        isItemLoaded={(index) => isLoading}
        itemCount={currPage}
        loadMoreItems={fetchMoreTasks}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer style={{ height: '100%' }}>
            {({ height, width }: { height: number, width: number }) => (
              <div style={{ height: '100%', width: '100%' }}>
                <List
                  className={styles.cards}
                  height={height} // Установите желаемую высоту списка
                  itemCount={isEnd ? currTasks.length : currTasks.length + 1}
                  itemSize={130} // Установите высоту каждого элемента
                  width={width} // Или другая желаемая ширина
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  style={{ padding: '15px' }}
                >
                  {({ index, style }) => {
                    if (index === currTasks.length) {
                      if (isLoading) {
                        return <div style={style}>Loading...</div>;
                      } else {
                        return null;
                      }
                    }
                    return (
                      <div style={{ ...style, padding: '15px', justifyContent: 'center' }}>
                        <NewCard
                          key={currTasks[index].UID}
                          cardInfo={currTasks[index]}
                          UID={currTasks[index].UID}
                          isActive={currTasks[index].UID === application}
                          onClickHandler={() => { }}
                        />
                      </div>
                    );
                  }}
                </List>
              </div>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>

      {/* <div
        className={styles.cards}
        onScroll={handleScroll}
        ref={containerRef}
      >
        {currTasks.length === 0 ? (
          <div className={styles.noApps}>
            {isLoading ? <Preloader /> : <p className={styles.text}>Заявок нет</p>}
          </div>
        ) : (
          <>
            {currTasks.map((item) => (
              <Card
                key={item.UID}
                cardInfo={item}
                UID={item.UID}
                isActive={item.UID === application}
                onClickHandler={() => { }}
              />
            ))}
            {currTasks.map((item) => (
              <NewCard
                key={item.UID}
                cardInfo={item}
                UID={item.UID}
                isActive={item.UID === application}
                onClickHandler={() => { }}
              />
            ))}
            {isLoading && <Preloader />}
            {isEnd && <p className={styles.text}>Данные закончились</p>}
          </>
        )}
      </div> */}

      {isOpen && <ModalAppFilter />}
    </div>
  );
};

export const ApplicationsModal = ({
  isAppListOpen,
  setAppListOpen,
}: {
  isAppListOpen: boolean;
  setAppListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setAppListOpen(!isAppListOpen)}
        direction={isAppListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isAppListOpen && styles.applications_open)}>
        <Applications />
      </div>
    </div>
  );
};
