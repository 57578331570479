import imageCompression from 'browser-image-compression';

import { AppType, CommentType, IFilter } from '@shared/types';
import { NewTaskStatus, PriorityType, TaskStatus } from '@shared/enums';
import { handleSendNewComment, handleUploadSomeFile } from 'api';

export const handleBackgroundStatus = (v: TaskStatus | NewTaskStatus) => {
  switch (v) {
    case 'Черновик':
      return '#8391A5';
    case 'Новая':
      return '#FABB18';
    case 'Ожидание':
      return '#FABB18';
    case 'В работе':
      return '#378DFE';
    case 'Отменена':
      return '#c0c0c0';
    // case 'Отложена':
    //   return '#FABB18';
    // case 'Подтверждение':
    //   return '#91BD13';
    case 'Выполнена':
      return '#91BD13';
    default:
      return '#FC0800';
  }
};

export const handlePriorityColor = (v: PriorityType) => {
  switch (v) {
    case 'Очень низкий':
      return '#91BD13';
    case 'Низкий':
      return '#91BD13';
    case 'Средний':
      return '#FABB18';
    case 'Важный':
      return '#FF0800';
    case 'Очень важный':
      return '#FF0800';
    default:
      return '#363942';
  }
};

export const handleFilterTasks = (v: IFilter, tasks: AppType[]) => {
  const {
    servFilter,
    categoryFilter,
    statusFilter,
    dateRangeFilter,
    userFilter,
    initiatorFilter,
    coordinatorFilter,
    isWithoutExecutor,
  } = v;
  return tasks
    .filter((item) => (initiatorFilter.Name ? item.Initiator.UID === initiatorFilter.UID : item))
    .filter((item) => (userFilter.Name ? item.Executor?.UID === userFilter.UID : item))
    .filter((item) =>
      coordinatorFilter.Name ? item.Coordinator?.UID === coordinatorFilter.UID : item
    )
    .filter((item) => (servFilter.Name ? item.Service?.UID === servFilter.UID : item))
    .filter((item) => (categoryFilter.Name ? item.Category?.UID === categoryFilter.UID : item))
    .filter((item) => (statusFilter.length ? statusFilter.includes(item.Status) : item))
    .filter((item) =>
      dateRangeFilter[0]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') >= dateRangeFilter[0]
        : item
    )
    .filter((item) =>
      dateRangeFilter[1]
        ? new Date(item.Date)[Symbol.toPrimitive]('number') <= dateRangeFilter[1]
        : item
    )
    .filter((item) => (isWithoutExecutor ? !item.Executor?.UID : item));
};

const dateItem = (v: string) => {
  return v.length > 1 ? v : '0' + v;
};

export const handleDateFormat = (v: Date) => {
  const dateMonth = v.getMonth().toLocaleString();
  const dateDay = v.getDay().toLocaleString();
  const dateHour = v.getHours().toLocaleString();
  const dateMin = v.getMinutes().toLocaleString();
  const dateSec = v.getSeconds().toLocaleString();
  return `${v.getFullYear()}-${dateItem(dateMonth)}-${dateItem(dateDay)}T${dateItem(
    dateHour
  )}:${dateItem(dateMin)}:${dateItem(dateSec)}`;
};

export const getBase64 = (file: any) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

export const handleNewTextComment = (
  newComment: string,
  setNewComment: (value: React.SetStateAction<string>) => void,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
  appUID: string,
  setCommArr: (value: React.SetStateAction<CommentType[]>) => void,
  commArr: CommentType[],
  userUID: string,
  userName: string,
  userAvatar: string
) => {
  if (/^\s*$/.test(newComment)) {
    return setNewComment('');
  }
  setIsLoading(true);
  handleSendNewComment({ UID: appUID, Note: newComment.trim() })
    .then(() => {
      setCommArr([
        ...commArr,
        {
          SentFrom: {
            UID: userUID,
            Name: userName,
            Img: userAvatar
          },
          CommentDateTime: handleDateFormat(new Date()),
          Note: newComment,
        },
      ]);
      return setNewComment('');
    })
    .catch((err) => console.error(err))
    .finally(() => setIsLoading(false));
};

export const handleNewFileComment = (
  e: React.ChangeEvent<HTMLInputElement>,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
  appUID: string,
  setCommArr: (value: React.SetStateAction<CommentType[]>) => void,
  commArr: CommentType[],
  userUID: string,
  userName: string,
  userAvatar: string
) => {
  if (!e.target.files?.length) return;
  setIsLoading(true);
  const currFile = e.target.files[0];
  e.target.value = '';
  if (currFile.type.split('/')[0] === 'image') {
    imageCompression(currFile, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    })
      .then((res) => getBase64(res))
      .then((base) => {
        if (typeof base === 'string') {
          handleSendNewComment({
            UID: appUID,
            Note: `<img src="${base}" alt="${currFile.name}" />`,
          })
            .then(() =>
              setCommArr([
                ...commArr,
                {
                  SentFrom: {
                    UID: userUID,
                    Name: userName,
                    Img: userAvatar
                  },
                  CommentDateTime: handleDateFormat(new Date()),
                  Img: base,
                },
              ])
            )
            .catch((err) => console.error(err))
        }
      }
      )
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  } else {
    handleUploadSomeFile(currFile, appUID)
      .then((res) => console.log(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }
};

// export function useHorizontalScroll() {
//   const elRef = useRef<null | HTMLDivElement>(null);
//   useEffect(() => {
//     const el = elRef.current;
//     if (el) {
//       const onWheel = (e: WheelEvent) => {
//         if (e.deltaY == 0) return;
//         e.preventDefault();
//         el.scrollTo({
//           left: el.scrollLeft + e.deltaY,
//           behavior: 'smooth',
//         });
//       };
//       el.addEventListener('wheel', onWheel);
//       return () => el.removeEventListener('wheel', onWheel);
//     }
//   }, []);
//   return elRef;
// }

// const debounce = (callback: () => void, delay: number) => {
//   let timeoutID: any;
//   return (...args: any[]) => {
//     clearTimeout(timeoutID);
//     timeoutID = setTimeout(() => {
//       timeoutID = null;
//       callback(...args);
//     }, delay);
//   };
// };

// function debounce<T extends Function>(callback: T, delay: number) {
//   let timeoutID: any;
//   let callable = (...args: any[]) => {
//     clearTimeout(timeoutID);
//     timeoutID = setTimeout(() => callback(...args), delay);
//   };
//   return <T>(<any>callable);
// }
