import React, { useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '@redux/store';
import { setUpdateInterval } from '@redux/slices/notificationSlice';
import styles from '../MoreSettings.module.scss';

type OptionValue = { value: number; label: string };

// массив от нуля до 60 минут
function getMinutesFromHour() {
  const minutes = [];
  minutes.push({ value: 0, label: 'при уведомлениях' });
  for (let min = 1; min <= 60; min++) {
    minutes.push({ value: min, label: `каждые ${min} мин.` });
  }
  return minutes;
}

const optionsValues = getMinutesFromHour();

// стилизация выпадающего списка
const colorStyles: StylesConfig<OptionValue> = {
  control: (styles) => ({ ...styles, fontFamily: `Roboto, Arial`, minWidth: `180px` }),
  indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
  option: (styles) => ({ ...styles, fontFamily: `Roboto, Arial` }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
};

const RefreshComponent = () => {
  const [currValue, setCurrValue] = useState(optionsValues[0]);
  const { updateInterval } = useSelector((state: RootState) => state.notifications);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const updateIntervalLS = localStorage.getItem('updateInterval');
    let value: OptionValue | undefined;
    if (updateIntervalLS) {
      value = optionsValues.find((item) => item.value === +updateIntervalLS);
    } else {
      value = optionsValues.find((item) => item.value === updateInterval);
    }
    setCurrValue(value || optionsValues[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (option: OptionValue | null) => {
    if (!option) return;
    dispatch(setUpdateInterval(option?.value));
    setCurrValue(option);
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>Обновлять</p>
      <Select
        styles={colorStyles}
        value={currValue}
        options={optionsValues}
        onChange={(item) => onChange(item as OptionValue)}
      />
    </div>
  );
};

export default React.memo(RefreshComponent);
