import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './MoreSettings.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';
import userAvatar from '@assets/userLogo.svg';
import NotificationComponent from './NotificationComponent';
import PwaComponent from './PwaComponent';
import RefreshComponent from './RefreshComponent';

interface IProps {
  isSettingsOpen: boolean;
  setSettingsOpen: (v: boolean) => void;
  setResultModal: (v: boolean) => void;
}

const MoreSettings: React.FC<IProps> = ({ isSettingsOpen, setSettingsOpen, setResultModal }) => {
  const { userInfo } = useContext(AuthContext);

  return (
    <div className={styles.info}>
      <div className={styles.heading}>
        <span className={styles.formBackground}></span>
        <Link to={'/profile'}>
          <img
            src={userInfo.Avatar || userAvatar}
            alt="user avatar"
            className={styles.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = userAvatar;
            }}
          />
        </Link>
        <h3 className={styles.title}>{userInfo.FullName}</h3>
        {/* <h4 className={styles.subtitle}>{userInfo.Department.Name || ''}</h4> */}
      </div>

      <div className={styles.body}>
        {isSettingsOpen ? (
          <>
            <Link to={'/profile'} className={styles.link}>
              <span className={styles.profile} />
              <p className={styles.text}>Профиль</p>
            </Link>
            {/* <Link to={'/services'} className={styles.link}>
              <span className={styles.services} />
              <p className={styles.text}>Сервисы</p>
            </Link>
            <Link to={'/reports'} className={styles.link}>
              <span className={styles.reports} />
              <p className={styles.text}>Отчеты</p>
            </Link> */}
            <div className={styles.link} onClick={() => setSettingsOpen(false)}>
              <span className={styles.settings} />
              <p className={styles.text}>Настройки</p>
            </div>
          </>
        ) : (
          <>
            <Link to={'/edit-password'} className={styles.link}>
              <span className={styles.password} />
              <p className={styles.text}>Изменить пароль</p>
            </Link>
            <a href="mailto:helpdesk@rixap.ru" className={styles.link}>
              <span className={styles.rixap} />
              <p className={styles.text}>Связаться с разработчиком</p>
            </a>
            <PwaComponent />
            <NotificationComponent />
            <RefreshComponent />
          </>
        )}

        <div className={styles.exitContainer} onClick={() => setResultModal(true)}>
          <span className={styles.exitLogo} />
          <p className={styles.exitText}>Выйти</p>
        </div>
      </div>
    </div>
  );
};

export default MoreSettings;
