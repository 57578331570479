import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './DefComp.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';
import userAvatar from '@assets/userLogo.svg';

import secondImage from '@assets/defPage_images/knowBase.png';
import ModalPWA from '@components/Modal/ModalUpdate/ModalUpdate';

interface IProps {
  waitingWorker: ServiceWorker | null;
  showReload: boolean;
  reloadPage: () => void;
}

const DefComp: React.FC<IProps> = ({ waitingWorker, showReload, reloadPage }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AuthContext);
  const [isUpdated, setUpdated] = useState(true);

  // обработчик кнопки обновить приложение
  useEffect(() => {
    if (showReload && waitingWorker) {
      setUpdated(true);
    } else setUpdated(false);
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <div className={styles.component}>
      <div className={styles.heading}>
        <Link to="/profile" className={styles.userInfo}>
          <img
            className={styles.userAvatar}
            src={userInfo.Avatar || userAvatar}
            alt="user avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = userAvatar;
            }}
          />

          <h2 className={styles.userName}>{userInfo.Name || userInfo.FullName}</h2>
        </Link>
        <p className={styles.text}>
          Сегодня {new Date().toISOString().split('T')[0].split('-').reverse().join('.')}
        </p>
      </div>
      <div className={styles.projects}>
        <div className={styles.items}>
          <div>
            <h3 className={styles.title}>Часто задаваемые вопросы</h3>
          </div>
          <button className={styles.button} onClick={() => navigate('/knowledge')}>
            Изучить
          </button>
        </div>
        <img className={styles.defImage} src={secondImage} alt="some logo" />
      </div>
      <ModalPWA
        isOpen={isUpdated}
        closeModal={() => setUpdated(false)}
        handleConfirm={() => reloadPage()}
      />
    </div>
  );
};

export default DefComp;
