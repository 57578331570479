import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface notificationState {
  notificationsNumber: number;
  updateInterval: number;
}

const initialState: notificationState = {
  notificationsNumber: 0,
  updateInterval: 0,
};

const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotificationsNumber(state, action: PayloadAction<{ Number: number }>) {
      return { ...state, notificationsNumber: action.payload.Number };
    },
    setUpdateInterval(state, action: PayloadAction<number>) {
      localStorage.setItem('updateInterval', `${action.payload}`);
      return { ...state, updateInterval: action.payload };
    },
  },
});

export const { addNotificationsNumber, setUpdateInterval } = NotificationSlice.actions;

export default NotificationSlice.reducer;
