import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './KnowItems.module.scss';
import { handleDownloadKnowBase } from 'api';
import firstImage from '@assets/knowBase/knowBase_1.png';
import secondImage from '@assets/knowBase/knowBase_2.png';
import Preloader from '@components/UI/Preloader/Preloader';

interface IProps {
  Name: string;
  UID: string;
}

const KnowItems = () => {
  const [knowBaseArr, setKnowBaseArr] = useState<IProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    handleDownloadKnowBase('')
      .then((res) => setKnowBaseArr(res))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.items}>
      {isLoading ? (
        <Preloader />
      ) : (
        knowBaseArr.map((item, i) => (
          <div className={styles.item} key={item.UID}>
            <img
              className={styles.image}
              alt={item.Name}
              src={i === 1 ? firstImage : secondImage}
            />
            <div
              className={styles.textContainer}
              onClick={() => navigate(`/knowledge/${item.UID}`)}
            >
              <p className={styles.heading}>{item.Name}</p>
              {/* <p className={styles.subheading}>{item.UID}</p> */}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default KnowItems;
