import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import appsSlice from './slices/appsSlice';
import filterSlice from './slices/filterSlices';
import notificationSlice from './slices/notificationSlice';

export const store = configureStore({
  reducer: {
    apps: appsSlice,
    filter: filterSlice,
    notifications: notificationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
