import React from 'react';

import styles from './KnowBaseDeskPage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import { NavSideBar } from '@components/NavSideBar/NavSideBar';
import SubHeader from '@components/SubHeader/SubHeader';
import { Applications } from '@components/Applications/Applications/Applications';
import KnowItems from '@components/KnowItems/KnowItems';

const KnowBaseDeskPage = () => {
  return (
    <main className={styles.page}>
      <NavSideBar />
      <Header deskPageName="Техподдержка" />
      <div>
        <SubHeader pageName={'Часто задаваемые вопросы'} />

        <div className={styles.body}>
          <Applications />
          <div className={styles.reports}>
            <KnowItems />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProtectedRoute(KnowBaseDeskPage);
