import { useEffect, useState } from 'react';

/**
 * Дебаунсер для задержки между вводом в строку поиска и отправкой запроса на сервер
 * 
 * пример импорта в компонент:
 * const [value, setValue] = useState<string>('')
 * const debouncedValue = useDebounce<string>(value, 500)
 * const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
    // Fetch API (optional)
  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
  }, [debouncedValue])
 * <input type="text" value={value} onChange={handleChange} />
 */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}